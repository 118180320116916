<template>
    <LiefengContent>
        <template #title>
            谁看过
            <span class="eye" @click.stop="changeEye">
                <Icon :type="hasSensitive ? 'md-eye-off' : 'md-eye'" />
            </span>
            <ul class="readnum">
                <li>接收总户数：{{ totalNum }}</li>
                <li class="click" @click="getList(2)">已读户数：{{ readNum }}</li>
                <li class="click" @click="getList(1)">
                    未读户数：
                    <span style="color: red; text-decoration: underline">{{ unreadNum }}</span>
                </li>
            </ul>
        </template>
        <template #toolsbarRight>
            <Form inline label-colon>
                <FormItem>
                    <CascaderCity :orgCode="4401" :resetNum="resetNum" @changeCasader="changeCasader"></CascaderCity>
                </FormItem>
                <Button type="primary" style="margin-right: 10px" @click="search">查询</Button>
                <Button type="error" style="margin-right: 10px" @click="ExportNum">导出未读户数名单</Button>
                <Button type="error" style="margin-right: 10px" @click="ExportUser">导出未读户代表名单</Button>
                <!-- <Button
          type="warning"
          style="margin-right: 0"
          >提醒未读用户</Button
        > -->
                <Button type="primary" v-if="$route.query.type" @click="goback">返回</Button>
            </Form>
        </template>
        <template #contentArea>
            <LiefengTable
                :tableData="tableData"
                :talbeColumns="talbeColumns"
                :loading="loading"
                :pageSize="pageSize"
                :pagesizeOpts="[20, 30, 50, 100, 200]"
                :total="total"
                :fixTable="true"
                :curPage="page"
                @hadlePageSize="hadlePageSize"
            ></LiefengTable>

            <LiefengModal :title="housName" :value="detailStatus" @input="changeDetailStatus" height="500px" width="1000px">
                <template v-slot:title>
                    <ul class="readNum">
                        <li>共{{ detailTotal }}人</li>
                        <li>{{ detailReadNum }}人已读</li>
                        <li>
                            <span style="color: red">{{ detailUnreadNum }}</span>
                            人未读
                        </li>
                    </ul>
                </template>
                <template v-slot:contentarea>
                    <Table :loading="detailLoading" :data="detialTalbeDate" :columns="detialTalbeColumns" height="420"></Table>
                </template>
                <template v-slot:toolsbar>
                    <Button
                        type="primary"
                        style="marginright: 10px"
                        @click="
                            housName = ''
                            changeDetailStatus(false)
                        "
                    >
                        确定
                    </Button>
                </template>
            </LiefengModal>
        </template>
    </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengModal from "@/components/LiefengModal"
import LiefengTable from "@/components/LiefengTable"
import CascaderCity from "./children/CascaderCity.vue"
export default {
    data() {
        return {
            // 脱敏
            hasSensitive: true,
            resetNum: 0,
            // cascaderList:[],

            CascaderList: [],
            CascaderId: [],
            //菜单树社区编码
            orgCode: "",
            orgName: "",
            isNum: 0,
            searchLenght: 3,

            totalNum: 0,
            readNum: 0,
            unreadNum: 0,

            tableData: [],
            talbeColumns: [
                {
                    title: "社区/村",
                    key: "communityName",
                    minWidth: 140,
                    align: "center",
                },
                {
                    title: "街巷/经济社",
                    key: "roadName",
                    minWidth: 140,
                    align: "center",
                },
                {
                    title: "小区",
                    key: "projectName",
                    minWidth: 140,
                    align: "center",
                },
                {
                    title: "楼栋",
                    key: "buildingName",
                    minWidth: 140,
                    align: "center",
                },
                {
                    title: "房号",
                    key: "houseName",
                    minWidth: 140,
                    align: "center",
                },
                {
                    title: "现住地址",
                    key: "wholeAddr",
                    minWidth: 330,
                    align: "center",
                },
                {
                    title: "状态",
                    minWidth: 140,

                    align: "center",
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: {
                                    color: params.row.status == 1 ? "red" : "black",
                                },
                            },
                            params.row.status == 1 ? "未读" : "已读"
                        )
                    },
                },
                {
                    title: "操作",
                    width: 140,
                    align: "center",
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "info",
                                        size: "small",
                                    },
                                    style: {
                                        marginLeft: "14px",
                                    },
                                    on: {
                                        click: () => {
                                            this.onDetail(params.row)
                                        },
                                    },
                                },
                                "查看"
                            ),
                        ])
                    },
                },
            ],
            loading: false,
            pageSize: 20,
            total: 0,
            page: 1,

            detailLoading: false,
            detailStatus: false,
            detialTalbeColumns: [
                {
                    title: "姓名",
                    key: "name",
                    minWidth: 110,
                    align: "center",
                },
                {
                    title: "手机号",
                    key: "account",
                    minWidth: 110,
                    align: "center",
                },
                {
                    title: "是否户代表",
                    minWidth: 110,
                    align: "center",
                    render: (h, params) => {
                        return h("div", {}, params.row.contact == "1" ? "户代表" : "成员")
                    },
                },
                {
                    title: "状态",
                    minWidth: 110,
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: {
                                    color: params.row.isRead == "1" ? "red" : "black",
                                },
                            },
                            params.row.isRead == "1" ? "未读" : "已读"
                        )
                    },
                },
                {
                    title: "查看时间",
                    //   key: "readTime",
                    minWidth: 210,
                    align: "center",
                    render: (h, params) => {
                        return h("div", {}, params.row.readTime ? this.$core.formatDate(new Date(Number(params.row.readTime)), "yyyy-MM-dd hh:mm:ss") : "")
                    },
                },
            ],
            detialTalbeDate: [],
            housName: "房名",
            detailTotal: 0,
            detailReadNum: 0,
            detailUnreadNum: 0,
        }
    },
    methods: {
        changeCasader(val) {
            this.cascaderList = val
            if (this.resetNum == 0 && this.cascaderList.length == 3) {
                this.getList()
                this.getNum()
            }
        },
        //分页
        hadlePageSize(val) {
            this.page = val.page
            this.pageSize = val.pageSize
            this.getList()
        },
        onDetail(data) {
            this.housName = data.houseName
            this.changeDetailStatus(true)
            this.getDetail(data.houseNo)
        },
        changeDetailStatus(status) {
            this.detailStatus = status
        },
        search() {
            console.log("查询this.$Message.destroy();", this.cascaderList)
            if (this.cascaderList.length != 3) {
                this.$Message.warning({
                    content: "请选择到社区再进行查询",
                    background: true,
                })
                return
            }
            ;(this.page = 1), (this.pageSize = 20)
            this.getList()
            this.getNum()
        },
        //获取列表
        getList(status) {
            this.loading = true
            this.$get("/syaa/api/syuser/pc/msgHouse/queryDetailPage", {
                msgId: this.$route.query.msgId,
                orgCode: this.cascaderList[this.cascaderList.length - 1],
                page: this.page,
                pageSize: this.pageSize,
                readStatus: status,
                hasSensitive: this.hasSensitive,
            }).then(res => {
                if (res.code == 200) {
                    this.loading = false
                    this.tableData = res.dataList
                    this.total = res.maxCount
                    // this.page = res.maxPage
                } else {
                    this.loading = false
                    this.$Message.error({
                        content: "获取详情失败",
                        background: true,
                    })
                }
            })
        },
        getNum() {
            this.$get("/syaa/api/syuser/pc/msgHouse/getReadAmountByMsgIdAndOrgCode", {
                msgId: this.$route.query.msgId,
                orgCode: this.cascaderList[this.cascaderList.length - 1],
            }).then(res => {
                if (res.code == 200 && res.data) {
                    this.totalNum = res.data.houseAmount
                    this.readNum = res.data.readAmount
                    this.unreadNum = res.data.unreadAmount
                }
            })
        },
        //查看详情
        getDetail(houseNo) {
            this.detailTotal = 0
            this.detailUnreadNum = 0
            this.detailReadNum = 0
            this.detailLoading = true
            this.$get("/syaa/api/syuser/pc/msgHouse/queryDetailData", {
                houseNo: houseNo,
                msgId: this.$route.query.msgId,
            }).then(res => {
                if (res.code == 200) {
                    this.detailLoading = false
                    this.detialTalbeDate = res.dataList
                    ;(this.detailTotal = res.dataList.length),
                        this.detialTalbeDate.forEach(e => {
                            if (e.isRead == "1") this.detailUnreadNum++
                            if (e.isRead == "2") this.detailReadNum++
                        })
                } else {
                    this.detailLoading = false
                    this.$Message.error({
                        content: "获取详情失败",
                        background: true,
                    })
                }
            })
        },
        //导出未读户代表名单
        ExportUser() {
            if (this.tableData.length == 0) {
                this.$Message.warning({
                    content: "当前暂无数据",
                    background: true,
                })
                return
            }
            this.$Modal.confirm({
                title: "温馨提示",
                loading: true,
                content: "是否导出未读户代表名单",
                onOk: () => {
                    this.$get(
                        "/syaa/api/syuser/pc/msgHouse/exportNoReadData",
                        {
                            msgId: this.$route.query.msgId,
                            orgCode: this.cascaderList[this.cascaderList.length - 1],
                        },
                        { "Content-Type": "application/json" }
                    ).then(res => {
                        if (res.code == 200) {
                            this.$Modal.remove()
                            if (res.data) {
                                if (res.data.slice(0, 4) == "http" && res.data.slice(0, 5) !== "https") {
                                    res.data = res.data.replace("http", "https")
                                }
                            }

                            let link = document.createElement("a")
                            link.href = res.data
                            document.body.appendChild(link)
                            link.click()
                            link.download = "导出信息"
                            document.body.removeChild(link)
                            this.$Message.destroy()
                            this.templateStatus = false
                        } else {
                            this.$Modal.remove()
                            this.$Message.success({
                                content: "导出失败",
                                background: true,
                            })
                        }
                    })
                },
            })
        },
        //导出未读户数名单
        ExportNum() {
            if (this.tableData.length == 0) {
                this.$Message.warning({
                    content: "当前暂无数据",
                    background: true,
                })
                return
            }
            this.$Modal.confirm({
                title: "温馨提示",
                loading: true,
                content: "是否导出未读户数名单",
                onOk: () => {
                    this.$get(
                        "/syaa/api/syuser/pc/msgHouse/exportNoReadHouse",
                        {
                            msgId: this.$route.query.msgId,
                            orgCode: this.cascaderList[this.cascaderList.length - 1],
                        },
                        { "Content-Type": "application/json" }
                    ).then(res => {
                        if (res.code == 200) {
                            this.$Modal.remove()
                            if (res.data) {
                                if (res.data.slice(0, 4) == "http" && res.data.slice(0, 5) !== "https") {
                                    res.data = res.data.replace("http", "https")
                                }
                            }

                            let link = document.createElement("a")
                            link.href = res.data
                            document.body.appendChild(link)
                            link.click()
                            link.download = "导出信息"
                            document.body.removeChild(link)
                            this.$Message.destroy()
                            this.templateStatus = false
                        } else {
                            this.$Modal.remove()
                            this.$Message.success({
                                content: "导出失败",
                                background: true,
                            })
                        }
                    })
                },
            })
        },
        remind() {
            this.$Modal.confirm({
                title: "温馨提示",
                loading: true,
                content: "是否提醒未读用户",
                onOk: () => {
                    this.$get("", {}).then(res => {
                        if (res.code == 200) {
                            this.$Modal.remove()
                            this.$Message.success({
                                content: "提醒成功",
                                background: true,
                            })
                        } else {
                            this.$Modal.remove()
                            this.$Message.error({
                                content: "提醒失败",
                                background: true,
                            })
                        }
                    })
                },
            })
        },
        goback() {
            var index = parent.layer.getFrameIndex(window.name)
            if (index) {
                //先得到当前iframe层的索引
                parent.layer.close(index) //再执行关闭
            } else {
                let type = this.$route.query.type
                if (type == "answer") this.$router.push(`/answerindex?menuId=${this.$route.query.menuId}`)
                if (type == "questionnaire") this.$router.push(`/questionnaireindex?menuId=${this.$route.query.menuId}`)
                if (type == "vote") this.$router.push(`/voteindex?menuId=${this.$route.query.menuId}`)
                if (type == "order") this.$router.push(`/activityindex?menuId=${this.$route.query.menuId}`)
                if (type == "shop") this.$router.push(`/shopindex?menuId=${this.$route.query.menuId}`)
            }
        },
        changeEye() {
            this.hasSensitive = !this.hasSensitive
            this.getList()
        },
    },
    created() {
        console.log(this.$core.formatNumberToDate(new Date("Fri Jan 07 13:45:33 CST 2022").getTime(), "yyyy-MM-dd hh:mm:ss"))
    },
    components: {
        LiefengContent,
        LiefengModal,
        LiefengTable,
        CascaderCity,
    },
}
</script>
    
<style scoped lang='less'>
.readnum {
    display: inline;
    margin-left: 20px;
    font-size: 14px;
    font-weight: 700;
    li {
        display: inline;
        margin-left: 15px;
    }
    .click {
        text-decoration: underline;
        cursor: pointer;
    }
}
.readNum {
    margin-top: 5px;
    font-size: 14px;
    font-weight: 700;
    height: 20px;
    li {
        display: inline;
        margin-left: 15px;
    }
}
.eye {
    cursor: pointer;
    display: inline-block;
    font-size: 17px;
}
</style>